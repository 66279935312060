import Cookies from './js-cookie';

if(customElements.get('sticky-ribbon') === undefined)
customElements.define(
    "sticky-ribbon",
    class extends HTMLElement {
    constructor()
    {
        super();

        this.attachShadow({mode: "open"});

        const
            fragment = document.createRange().createContextualFragment(`
<div class="root">
    <div class="wrapper">
        <div class="content">
            ${this.innerHTML}
            <a class="cta"></a>
        </div>
        <a class="close">
            X
        </a>
    </div>
</div>
`),
            style = document.createElement("style")
        ;

        style.textContent = `
.root {
    background: ${this.getAttribute('data-background-color')};
    border: 2px solid ${this.getAttribute('data-background-color')};
    color: ${this.getAttribute('data-text-color')};
    position: relative;
    width: 100%;
}

.wrapper {
    align-items: center;
    display: flex;
    min-height: 35px;
    justify-content: center;
    padding: 0 8px;
    position: relative;
}

.content {
    font-size: 14px;
    line-height: 1.3;
    padding-right: 24px;
    text-align: center;
}

a {
    line-height: 1;
    text-decoration: none;
}

p {
    display: inline;
    margin: 0;
    padding: 0;
}

.cta {
    background: ${this.getAttribute('data-cta-background-color')};
    border-radius: .3rem;
    color: ${this.getAttribute('data-cta-text-color')};
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 .8rem;
    padding: .5rem 1.2rem;
}

.close:hover,
.cta:hover {
    opacity: 0.9;
}

.close {
    color: ${this.getAttribute('data-close-icon-color')};
    cursor: pointer;
    display: block;
    font-size: 20px;
    font-weight: 400;
    position: absolute;
    text-align: center;
    right: 0;
    top: 8px;
    width: 40px;
    z-index: 1;
}
`;
        this.shadowRoot.appendChild(style);
        this.shadowRoot.appendChild(fragment.cloneNode(true));
        this.shadowRoot.querySelector('.cta').setAttribute('href', this.getAttribute('data-cta-link'));
        this.shadowRoot.querySelector('.cta').innerHTML = this.getAttribute('data-cta-text');
    }

    connectedCallback()
    {
        this.shadowRoot.querySelector('.close').addEventListener("click", e => {
            jQuery(this.shadowRoot.querySelector('.root')).hide();
            Cookies.set('ribbon-close-' + this.getAttribute('data-id'), true, { expires: 7 });
            jQuery(window).trigger('resize');
        });

        jQuery(window).trigger('resize');

        let
            s=document.querySelector(".header"),
            e=document.querySelector(":root")
        ;
        e.style.setProperty("--header-height",`${s.getBoundingClientRect().height}px`);
    }
    }
);

jQuery( document ).ready(async() => {
    const
        response = await fetch("/wp-json/wp/v2/ribbons"),
        ribbons = await response.json()
    ;

    if (!ribbons.length)
        return
    ;

    const ribbonResult = [];
    for (let k in ribbons) {
        if (!Cookies.get('ribbon-close-' + ribbons[k].id))
            ribbonResult.push(ribbons[k])
        ;
    }

    if (!ribbonResult.length)
        return
    ;

    let ribbon;
    for (let k in ribbonResult)
        if (!Cookies.get('ribbon-view-' + ribbonResult[k].id))
            ribbon = ribbonResult[k]
    ;

    if (!ribbon) {
        for (let k in ribbons)
            Cookies.remove('ribbon-view-' + ribbons[k].id)
        ;

        ribbon = ribbons.pop();
    }

    Cookies.set('ribbon-view-' + ribbon.id, true, { expires: 7 });

    jQuery('header.header').prepend(`<sticky-ribbon is="sticky-ribbon"
     class="sticky-ribbon"
     data-id="${ribbon.id}"
     data-background-color="${ribbon.acf.background_color}"
     data-close-icon-color="${ribbon.acf.close_icon_color}"
     data-cta-background-color="${ribbon.acf.cta_background_color}"
     data-cta-link="${ribbon.acf.cta_link.url}"
     data-cta-text="${ribbon.acf.cta_text}"
     data-text-color="${ribbon.acf.text_color}"
     data-cta-text-color="${ribbon.acf.cta_text_color}">${ribbon.content.rendered}</sticky-ribbon>`);
});
